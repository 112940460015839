body {
  --color-main: #05668d;
  --color-highlight: #028090;
  --color-light: #eee;
  --color-ok: #00a896;
  --color-danger: #e76f51;
  --color-neutral: #999;
  --color-text: #555;
}
.App {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 400;
  position: relative;
}

h3 {
  font-weight: 500;
}

th {
  font-weight: 500;
  text-align: left;
}

td {
  border: 1px solid var(--color-main);
  padding: 4px;
  margin: 4px;
}

input,
textarea {
  font-family: "Roboto";
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: transparent;
  padding: 5px 10px;
  border: 2px solid #999;
  border-radius: 6px;
  font-weight: 500;
  font-family: Roboto;
  color: #333;
  cursor: pointer;
}

button.small {
  border-radius: 4px;
  padding: 2px 5px;
}

button:hover {
  box-shadow: inset 0 0 0 20px rgba(100, 100, 100, 0.2);
}

.disabled button {
  cursor: default;
}

.disabled button:hover {
  box-shadow: none;
}

.button-delete {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
  color: #fff;
}

.button-edit {
  background-color: var(--color-ok);
  border-color: var(--color-ok);
  color: #fff;
}

.button-cancel {
  background-color: var(--color-neutral);
  border-color: var(--color-neutral);
  color: #fff;
}

.page-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-main);
  color: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.page-header > div {
  padding: 10px;
}

.page-header a {
  text-decoration: none;
  color: white;
}

.page-header a:hover {
  color: #c8efff;
}

.account-area {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.account-area .logged-in-user {
  font-weight: 500;
  display: block;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.account-area > a,
.sign-out-button {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 2px solid #ccc;
}

.sign-out-button {
  cursor: pointer;
  color: #fff;
}

.sign-out-button:hover {
  color: #c8efff;
}

.profile-container {
  max-width: 400px;
  margin: auto;
}

.profile-container > div {
  margin: 10px 0;
}

.profile-container .display-name-input {
  padding: 5px;
  width: 300px;
}

.reset-password-link {
  color: steelblue;
  cursor: pointer;
}

.reset-password-link:hover {
  color: seagreen;
}

.invite-code-container {
  width: 250px;
  margin: 20px auto;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 10px;
}

.invite-input {
  margin: 5px 0;
  font-size: 1rem;
  padding: 0 5px;
  height: 24px;
  width: calc(100% - 20px);
}

.invite-error {
  color: var(--color-danger);
}

.loading-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* https://loading.io/css/ */
.loader {
  display: inline-block;
  width: 48px;
  height: 48px;
}
.loader:after {
  content: " ";
  display: block;
  width: 36px;
  height: 36px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid rgba(100, 100, 100, 0.5);
  border-color: rgba(100, 100, 100, 0.5) rgba(200, 200, 200, 0.5)
    rgba(100, 100, 100, 0.5) transparent;
  animation: loader-anim 1.2s linear infinite;
}
@keyframes loader-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-big {
  width: 100px;
  height: 100px;
}

.loader-big:after {
  width: 78px;
  height: 78px;
  border-width: 10px;
}

.loader-small {
  width: 14px;
  height: 14px;
}

.loader-small:after {
  width: 11px;
  height: 11px;
  border-width: 2px;
}

.bold {
  font-weight: 500;
}

.italic {
  font-style: italic;
}

.spoiler {
  background-color: #555;
}

.spoiler:hover {
  background-color: #eee;
}

span.space {
  display: block;
  height: 0.8rem;
}

img {
  max-width: 300px;
  max-height: 250px;
}

@media only screen and (min-width: 768px) {
  img {
    max-width: 600px;
    max-height: 400px;
  }
}

a.user-link {
  color: var(--color-main);
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  font-size: 0.9em;
}

.footer > div {
  margin: 0 10px;
  border: 1px solid var(--color-neutral);
  padding: 2px 5px;
  border-radius: 4px;
}

.message-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-ok);
  color: var(--color-light);
  padding: 5px;
}

.message-banner button {
  margin-left: 10px;
  color: var(--color-light);
  background-color: var(--color-main);
  border-color: var(--color-light);
}

.message-banner button:hover {
  background-color: var(--color-highlight);
}

.tabs {
  display: flex;
  border-bottom: 2px solid var(--color-main);
  margin-bottom: 5px;
}

.tabs .tab {
  display: flex;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--color-main);
  border: 2px solid var(--color-neutral);
  border-top-left-radius: 8px;
  border-top-right-radius: 2px;
  border-bottom: none;
  padding: 5px;
  margin-right: 8px;
  background-color: var(--color-light);
  color: var(--color-neutral);
}

.tabs .tab.selected {
  background-color: transparent;
  color: var(--color-main);
  border-color: var(--color-main);
  box-shadow: 0 4px 0 -2px white;
}

button.button-sort {
  width: 22px;
  height: 20px;
  padding: 3px;
}

.button-sort > .none {
  width: 12px;
  height: 2px;
  background-color: var(--color-neutral);
}

.button-sort > .up {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid var(--color-ok);
}

.button-sort > .down {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid var(--color-ok);
}

a.user-name-link {
  color: var(--color-ok);
  text-decoration: none;
}

a.user-name-link:hover {
  color: var(--color-highlight);
}
