.page-message-container {
  display: flex;
  height: 100vh;
  font-size: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-message-container > div {
  margin: 15px 0;
}

.page-message-container a {
  color: steelblue;
  cursor: pointer;
}

.section-header {
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-main);
  margin-bottom: 2px;
}

.section-header .breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.section-header .title-edit-input {
  font-size: 0.9rem;
  padding: 0.3rem;
  width: 360px;
}

.section-header .thread-label {
  font-weight: 500;
  margin-right: 8px;
  text-decoration: none;
  color: var(--color-main);
}

.section-header a.thread-label:hover {
  opacity: 0.8;
}

.section-header .thread-buttons {
  display: flex;
}

.section-header button {
  margin-left: 10px;
}

.section-header .title-caret {
  font-weight: 500;
  margin-right: 8px;
}

.section-header .thread-title {
  font-weight: 400;
}

.forum-list-container,
.thread-list-container {
  margin: 30px auto;
  width: 90%;
  max-width: 400px;
}

@media only screen and (min-width: 768px) {
  .forum-list-container,
  .thread-list-container {
    max-width: 800px;
  }
}

.forum-row,
.thread-row,
.post-container {
  width: 100%;
  border: 1px solid var(--color-highlight);
  border-radius: 4px;
  padding: 5px;
  margin: 8px 0;
  box-sizing: border-box;
  background-color: white;
}

.forum-row,
.thread-row {
  display: flex;
  justify-content: space-between;
}

.forum-row {
  align-items: center;
}

.thread-row {
  align-items: stretch;
}

.thread-row.thread-unread {
  box-shadow: inset 0 0 0 1px var(--color-highlight);
}

.forum-row .forum-title {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  font-weight: 500;
  min-width: 200px;
}

@media only screen and (min-width: 768px) {
  .forum-row .forum-title {
    min-width: 300px;
  }
}

.post-container.editing {
  background-color: var(--color-light);
}

.post-container.disabled {
  opacity: 0.5;
}

.post-edited {
  font-size: 0.875rem;
  margin: 0px 5px 10px 5px;
}

.post-edited span:not(:first-child) {
  margin-left: 5px;
}

.post-edited .edit-data {
  color: var(--color-ok);
  font-weight: 500;
}

.forum-row,
.thread-row {
  display: flex;
  justify-content: space-between;
  display: flex;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.forum-row:hover,
.thread-row:hover {
  box-shadow: inset 0 0 0 100px rgba(200, 200, 200, 0.2);
}

.thread-row .thread-title {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.thread-row .thread-title span {
  font-size: 0.8rem;
  margin: 0 2px;
}

.thread-row .thread-title .title-container {
  display: inline-block;
}

.thread-row .thread-title .icon {
  margin-right: 5px;
}

.thread-row .thread-title .title-text {
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  color: var(--color-main);
}

.thread-row .thread-title .title-page-link {
  font-size: 0.8rem;
  margin: 0 2px;
  margin-left: 5px;
  color: var(--color-main);
  text-decoration: underline;
}

.thread-row .thread-title .title-page-link:hover {
  font-weight: 500;
}

.thread-row .thread-meta {
  font-size: 0.8rem;
}

.thread-meta .post-count {
  font-size: 0.9rem;
  flex-wrap: none;
}

.thread-meta .post-count .post-num {
  font-weight: 500;
  color: var(--color-main);
  display: inline-block;
}

.forum-row .forum-meta {
  font-size: 1rem;
}

@media only screen and (min-width: 768px) {
  .forum-row .forum-meta,
  .thread-row .thread-meta .last-updated-info {
    display: flex;
    align-items: flex-end;
  }

  .thread-row .thread-meta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.forum-row .forum-meta span,
.thread-row .thread-meta span {
  margin: 0 2px;
  display: block;
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .forum-row .forum-meta span,
  .thread-row .thread-meta span {
    margin: 0 2px;
    display: inline-block;
  }
}

.forum-row .info,
.thread-row .info {
  color: var(--color-main);
  font-weight: 500;
}

.forum-row .truncatable-name,
.thread-row .truncatable-name {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.forum-row .loader,
.thread-row .loader,
.post-container .loader {
  display: block;
  margin: auto;
}

.title-input {
  width: 100%;
  max-width: 800px;
  height: 30px;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
}

.form-line {
  margin: 10px 0;
}

.form-line label {
  font-weight: 500;
  display: block;
  margin-bottom: 3px;
}

.post-list-container {
  margin: 30px auto;
  width: 90%;
  max-width: 800px;
}

.post-container.unread,
.thread-row.unread,
.forum-row.unread {
  box-shadow: inset 0 0 0 2px var(--color-highlight);
}

.icon-comment {
  color: var(--color-highlight);
}

.forum-title .icon-comment {
  margin-right: 5px;
}

.post-container .post-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-light);
  padding: 5px;
}

.post-container .post-header .post-user {
  font-weight: 500;
  display: flex;
  align-items: flex-end;
}

.post-container .avatar-post {
  width: 60px;
  height: 60px;
  margin-right: 5px;
}

.post-container .post-header .post-date {
  font-weight: 400;
  font-size: 0.9rem;
  display: flex;
  align-items: flex-end;
}

.post-header-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.post-container .post-footer {
  border-top: 1px solid var(--color-light);
  padding: 5px 5px 0 5px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.post-container .post-footer > div {
  margin: 2px 0;
}

.post-container .post-footer button:not(:last-child) {
  margin-right: 10px;
}

.post-content {
  padding: 15px 5px;
  color: #555;
  overflow-x: auto;
}

.post-line {
  min-height: 20px;
  margin: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.post-field-label {
  font-weight: 500;
  width: 100px;
  text-align: left;
}

.new-post-container {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 2px solid var(--color-main);
}

.content-line {
  min-height: 10px;
  margin: 0;
  text-align: left;
}

.content-input {
  width: 100%;
  max-width: 800px;
  height: 200px;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  font-size: 0.9rem;
  line-height: 1.2rem;
}

.quote-box {
  border: 1px solid var(--color-highlight);
  width: calc(100% - 40px);
  margin: 5px auto;
  background-color: #efefef;
  padding: 5px;
}

.quote-box .quote-info {
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #ddd;
  padding: 2px 4px;
}

.pagination-control {
  font-weight: 400;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-main);
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
  flex-wrap: wrap;
}

.pagination-control .page-link {
  margin-left: 3px;
  text-decoration: none;
  color: white;
  padding: 0 2px;
}

.pagination-control .page-link.selected {
  border: 1px solid white;
}

.reactions-container {
  display: flex;
}

.reaction-button {
  background-color: var(--color-neutral);
  border-radius: 15px;
  color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
  transition: width 300ms;
  width: 28px;
}

.reaction-button.has-count {
  background-color: var(--color-main);
  width: 40px;
}

button.reaction-button:hover {
  box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.2);
}

.reaction-button:not(:first-child) {
  margin-left: 5px;
}

.reaction-button.user-selected {
  background-color: var(--color-ok);
}

.reaction-tooltip {
  position: absolute;
  top: 25px;
  left: 0;
  border-radius: 8px;
  background-color: rgba(0, 60, 100, 0.9);
  padding: 8px;
  z-index: 10;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 250ms;
  pointer-events: none;
  text-align: left;
}

.reaction-button {
  outline: 0;
}

.reaction-button:hover .reaction-tooltip {
  opacity: 1;
}

.reaction-tooltip .reaction-users {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.reaction-tooltip .reaction-user-name {
  font-weight: 300;
  font-size: 0.8rem;
}

.reaction-tooltip .reaction-user-name:first-child {
  border-top: 1px solid white;
  margin-top: 4px;
  padding-top: 4px;
}

.icon-reaction {
  color: white;
}

.reaction-count {
  margin-left: 5px;
}

.role-icon {
  border-radius: 50%;
  color: var(--color-main);
  border: 2px solid var(--color-main);
  width: 1rem;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}
