.create-account-container {
  margin: auto;
  width: 400px;
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-wrap: wrap;
  margin: 7px 0;
}

.form-row label {
  width: 100px;
}

.form-row input {
  font-size: 0.9rem;
  padding: 4px;
  flex: 1;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.error-message {
  margin: 10px 0;
  padding: 4px;
  border: 1px solid var(--color-danger);
  border-radius: 4px;
  color: var(--color-danger);
}

button {
  font-size: 0.9rem;
}
